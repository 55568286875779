
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseCheckBox extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) state!: string;

  get checkIcon(): string {
    return this.state === 'notAllSelected' ? 'not-all-selected' : 'mark';
  }

  onChange(event: any): void {
    this.$emit('input', event.target.checked);
  }
}
